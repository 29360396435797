import { Box, Flex, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import { EMPTY_VALUE } from 'config/const'
import { OrderStatusesColor, OrderStatusesFlattenValues } from 'config/order'

/**
 * Used for E2E tests.
 */
const TAG = 'OrderStatus'
interface OrderStatusProps {
  status: string
}

const OrderStatus: FunctionComponent<OrderStatusProps> = ({ status }) => {
  const { t } = useTranslation('orders')

  return (
    <Flex align="center">
      <Box
        bg={OrderStatusesColor[status as OrderStatusesFlattenValues]}
        boxSize={{ base: '8px', md: '12px' }}
        rounded="full"
        mr="1.5"
      />
      <Text as="span" role="status">
        {t('pages.order.statuses', { count: Number(status) }, { default: EMPTY_VALUE })}
      </Text>
    </Flex>
  )
}

OrderStatus.displayName = TAG

export default OrderStatus
