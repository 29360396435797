import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr, VisuallyHidden } from '@chakra-ui/react'
import React, { FunctionComponent, ReactNode } from 'react'

import { ResponsiveTableContext } from 'components/elements/ResponsiveTable/Provider/context'

export interface TableHeadingType {
  title: string
  isHidden?: boolean
  content?: ReactNode
}

interface ResponsiveTableProps extends TableContainerProps {
  children: React.ReactNode
  headings: ReadonlyArray<TableHeadingType>
}

/**
 * Used for E2E tests.
 */
const TAG = 'ResponsiveTable'

const ResponsiveTable: FunctionComponent<ResponsiveTableProps> = ({ children, headings, ...props }) => {
  return (
    <ResponsiveTableContext.Provider value={headings}>
      <TableContainer overflowX={{ base: 'hidden', md: 'auto' }} data-testid={`${TAG}-container`} {...props}>
        <Table variant="simple">
          <Thead data-testid={`${TAG}-head`}>
            <Tr>
              {headings.map((heading) =>
                heading.isHidden ? (
                  <Th key={heading.title}>
                    <VisuallyHidden>{heading.title} </VisuallyHidden>
                    {heading.content}
                  </Th>
                ) : (
                  <Th key={heading.title}>
                    {heading.title}
                    {heading.content}
                  </Th>
                ),
              )}
            </Tr>
          </Thead>
          <Tbody>{children}</Tbody>
        </Table>
      </TableContainer>
    </ResponsiveTableContext.Provider>
  )
}

ResponsiveTable.displayName = TAG

export default ResponsiveTable
