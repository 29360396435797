import noop from 'lodash/noop'
import React, { FunctionComponent, ReactNode } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

interface OrderHistoryFormWrapperProps {
  children: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'OrderHistoryFormWrapper'

export interface OrderHistoryFormSchema {
  query: string
  selectedStatuses: string[]
}

const OrderHistoryFormWrapper: FunctionComponent<OrderHistoryFormWrapperProps> = ({ children }) => {
  const methods = useForm({
    defaultValues: { query: '', selectedStatuses: [] },
  })

  return (
    <FormProvider {...methods}>
      <form name={TAG} data-testid={TAG} onSubmit={methods.handleSubmit(noop)}>
        {children}
      </form>
    </FormProvider>
  )
}

OrderHistoryFormWrapper.displayName = TAG

export default OrderHistoryFormWrapper
