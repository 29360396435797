const DEFAULT_OPTIONS = {
  month: 'short',
  year: 'numeric',
  day: '2-digit',
} as const satisfies Intl.DateTimeFormatOptions

/**
 * Function to format date in a specific language.
 *
 * @example
 * ```ts
 * // lang = 'en'
 * formatDate('2023-02-28', lang) // 'Feb 28, 2023'
 * ```
 */
function formatDate(
  date: string | undefined,
  lang: string,
  options: Intl.DateTimeFormatOptions = DEFAULT_OPTIONS,
): string {
  return date ? new Date(date).toLocaleDateString(lang, options) : ''
}

export default formatDate
