import type { QueryFunctionContext } from '@tanstack/react-query'

import { fetchWithAzureToken } from 'azure/client/user'
import { AzureScopes } from 'config/auth'
import { RoutePaths } from 'config/routes'
import jsonOrEmptyTextMapper from 'utils/jsonOrEmptyTextMapper'
/**
 * @param {string} erpCustomerNumber - Erp customer number from business unit custom fields
 * @returns order history from azure
 * @link https://docs.commercetools.com/api/projects/business-units#businessunitpagedqueryresponse
 */

type QueryKey = [
  queryFnName: string,
  erpCustomerNumber: string | undefined,
  page: number,
  limit?: number,
  status?: string[],
  search?: string,
]

type OrderList = Pick<QueryFunctionContext<QueryKey>, 'queryKey'>

async function getOrderHistory({
  queryKey: [, erpCustomerNumber, page = 1, limit = 20, status, search = ''],
}: OrderList): Promise<OrdersResponse> {
  if (!erpCustomerNumber) {
    return Promise.reject(new Error('No customer number provided to get order history'))
  }

  const params = new URLSearchParams({
    erpCustomerNumber,
    page: page.toString(),
    limit: limit.toString(),
    ...(status && status.length && { status: status.join(',') }),
    ...(search && { search: search.toString() }),
  }).toString()

  const response = await fetchWithAzureToken(AzureScopes.Orders, `${RoutePaths.ordersApi}?${params}`)
  return await jsonOrEmptyTextMapper(response)
}

export default getOrderHistory
