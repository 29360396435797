import type { QueryFunctionContext } from '@tanstack/react-query'

import { fetchWithAzureToken } from 'azure/client/user'
import { AzureScopes } from 'config/auth'
import { RoutePaths } from 'config/routes'
import jsonOrEmptyTextMapper from 'utils/jsonOrEmptyTextMapper'
/**
 * @param {string} erpCustomerNumber - Erp customer number from business unit custom fields
 * @param {string} orderNumber - Order number from order detail
 * @returns order detail from azure
 */

type QueryKey = [queryFnName: string, orderNumber: string, erpCustomerNumber?: string]

type OrderList = Pick<QueryFunctionContext<QueryKey>, 'queryKey'>

async function getAzureOrder({ queryKey: [, orderNumber, erpCustomerNumber] }: OrderList): Promise<Order> {
  if (!orderNumber || !erpCustomerNumber) {
    return Promise.reject(new Error('orderNumber or customerNumber is missing'))
  }
  const params = new URLSearchParams({ orderNumber, erpCustomerNumber }).toString()

  const response = await fetchWithAzureToken(AzureScopes.Orders, `${RoutePaths.ordersApi}?${params}`)
  return await jsonOrEmptyTextMapper(response)
}

export default getAzureOrder
