import { Button, Icon, LinkBox, LinkOverlay, Show, Td, Tr, Text, Tag } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { isPast } from 'date-fns'; // Importing date-fns for date comparison
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext } from 'react'

import ViewIcon from 'assets/view_icon.svg?component'
import getOrder from 'azure/order/getOrder'
import { ResponsiveTableContext } from 'components/elements/ResponsiveTable/Provider/context'
import SectionLoading from 'components/elements/SectionLoading';
import OrderStatus from 'components/modules/Order/OrderDetails/OrderStatus'
import { RoutePaths } from 'config/routes'
import assignParamsToUrl from 'utils/assignParamsToUrl'
import formatDate from 'utils/formatDate'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface OrderHistoryTableRowProps extends OrderHeader { }

const OrderHistoryTableRow: FunctionComponent<OrderHistoryTableRowProps> = ({
  orderNumber,
  customerOrderNumber,
  orderDate,
  status,
}) => {
  const { lang, t } = useTranslation('orders')
  const headings = useContext(ResponsiveTableContext)

  // Fetch the erpCustomerNumber
  const businessUnit = useBusinessUnit()
  const erpCustomerNumber = businessUnit?.data?.current?.custom?.fields?.erpCustomerNumber

  // Fetch order details for payment status using useQuery
  const { data: orderDetails, isLoading, error } = useQuery(
    ['getOrder', orderNumber, erpCustomerNumber], // Query key
    getOrder, // Call the getOrder function with queryKey
    {
      enabled: !!orderNumber && !!erpCustomerNumber, // Fetch only when both are available
      refetchOnWindowFocus: false, // Optional: avoid refetching on window focus
    }
  )


  // Extract payment status details (dueDate and isPaid) if data is available
  const paymentStatus = orderDetails?.orderDetails?.invoices?.[0] || { dueDate: "", isPaid: false }
  const isDatePast = (dateString: string, paid: boolean): boolean => isPast(new Date(dateString)) && !paid;

  const confirmedDeliveryDate = orderDetails?.orderItems?.length ?
    orderDetails.orderItems[0].confirmedDeliveryDate ?? undefined : undefined;




  return (
    <LinkBox as={Tr} data-link="true">
      <Td data-label={headings[0].title} maxW={{ md: '250' }} wordBreak="break-word" whiteSpace="normal">
        <LinkOverlay as={NextLink} href={assignParamsToUrl(RoutePaths.order, { slug: orderNumber })}>
          {orderNumber}
        </LinkOverlay>
      </Td>
      <Td data-label={headings[1].title}>{customerOrderNumber}</Td>
      <Td data-label={headings[2].title}>{formatDate(orderDate, lang)}</Td>
      <Td data-label={headings[2].title}>{formatDate(confirmedDeliveryDate, lang)}</Td>
      <Td data-label={headings[3].title}>
        <OrderStatus status={status} />
      </Td>

      {/* Display payment status */}
      <Td data-label="Payment Status">
        {isLoading ? (
          <SectionLoading /> // Show loading indicator while fetching
        ) : error ? (
          'Error fetching payment' // Handle errors
        ) : (
          <>
            {/* Conditionally render both due date and tag only if paymentStatus.dueDate is not empty */}
            {paymentStatus.dueDate && (
              <>
                <Text as="span" role="status"
                  color={isDatePast(paymentStatus.dueDate, paymentStatus.isPaid) ? 'red.500' : 'gray.800'}>
                  {t('pages.order.orderItemDetails.due')} : {formatDate(paymentStatus.dueDate, lang)}
                </Text>
                {/* Using Tag component as a pill */}
                <Tag
                  ml={2}
                  colorScheme={paymentStatus.isPaid ? "green" : "yellow"}
                  variant="solid"
                  borderRadius="full"
                >
                  {paymentStatus.isPaid ?
                    t('pages.order.orderItemDetails.paid') : t('pages.order.orderItemDetails.due')}
                </Tag>
              </>
            )}
          </>
        )}
      </Td>

      <Show below="sm">
        <Td justifyContent="center" mt="4">
          <Button
            w="full"
            leftIcon={<Icon aria-hidden="true" as={ViewIcon} boxSize={4} color="primary.blue" />}
            href={assignParamsToUrl(RoutePaths.order, { slug: orderNumber })}
            as={NextLink}
          >
            {t('pages.order.table.view')}
          </Button>
        </Td>
      </Show>
    </LinkBox>
  )
}

OrderHistoryTableRow.displayName = 'OrderHistoryTableRow'

export default OrderHistoryTableRow
